import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import * as actions from '../actions';
import * as selectors from '../selectors';
import { AppDispatch } from 'State/store';

// track requests to avoid spamming the server
const requestState = {
  isLoading: false,
};

const DEBOUNCE_TIME = 500; // ms

const useLocations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const ownedLocations = useSelector(selectors.locations.ownedLocations);
  const activeLocation = useSelector(selectors.locations.activeLocation);
  const activeLocationTimezone = useSelector(selectors.locations.activeLocationTimezone) || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const coAdminLocations = useSelector(selectors.locations.coAdminLocations);

  // Check if we need to fetch data
  const shouldFetchData = useMemo(() => {
    if (
      ownedLocations.isLoading ||
      coAdminLocations?.isLoading ||
      activeLocation.isLoading
    )
      return false;

    const needsData =
      ownedLocations.data?.length === undefined ||
      coAdminLocations?.data?.length === undefined;

    return needsData && !requestState.isLoading;
  }, [ownedLocations, coAdminLocations, activeLocation]);

  useEffect(() => {
    if (shouldFetchData) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        requestState.isLoading = true;
        dispatch(actions.locations.fetchData());
        requestState.isLoading = false;
      }, DEBOUNCE_TIME);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [shouldFetchData, dispatch]);

  return useMemo(
    () => ({
      activeLocation,
      activeLocationTimezone,
      locations: ownedLocations,
      coAdminLocations,
      availableLocationCount:
        (ownedLocations.data?.length || 0) +
        (coAdminLocations?.data?.length || 0),
      refetch: () => {
        dispatch(actions.locations.fetchData());
      },
    }),
    [activeLocation, ownedLocations, coAdminLocations]
  );
};

export default useLocations;
